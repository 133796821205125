const envvariable = {
  'local': {
    "REACT_APP_API_URL": 'http://localhost:8080',
    "REACT_APP_API_URL_SP": "https://terra-api-qa.stylishop.store",
    "REACT_APP_GCS_BUCKET_NAME": "supplier-storage-new",
    "SENTINEL_URL": "https://dev-sentinel-api.stylifashion.com/api",
    "sentinelAuthValidation": false
  },
  'development': {
    "REACT_APP_API_URL": 'https://auriga-api-dev.stylishop.store',
    "REACT_APP_API_URL_SP": "https://terra-api-dev.stylishop.store",
    "REACT_APP_GCS_BUCKET_NAME": "supplier-storage-new",
    "SENTINEL_URL": "https://dev-sentinel-api.stylifashion.com/api",
    "sentinelAuthValidation": false
  },
  "qa": {
    "REACT_APP_API_URL": 'https://auriga-api-qa.stylishop.store',
    "REACT_APP_API_URL_SP": "https://terra-api-qa.stylishop.store",
    "REACT_APP_GCS_BUCKET_NAME": "supplier-storage-new",
    "SENTINEL_URL": "https://dev-sentinel-api.stylifashion.com/api",
    "sentinelAuthValidation": false
  },
  "production": {
    "REACT_APP_API_URL": 'https://auriga-api-prod.stylishop.store',
    "REACT_APP_API_URL_SP": "https://terra-api-prod.stylishop.store",
    "REACT_APP_GCS_BUCKET_NAME": "auriga-storage",
    "SENTINEL_URL": "https://prod-sentinel-api.stylifashion.com/api",
    "sentinelAuthValidation": false
  }
}

exports.getEnvvariable = () => {
  const envvar = process.env.NODE_ENV;
  return envvariable[envvar];
}